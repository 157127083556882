<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="PayConfigSearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="支付名称:" prop="Name">
              <Input placeholder="名称" v-model="params.Name" clearable />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="支付类型:" prop="Type">
              <Select clearable v-model="params.Type" placeholder="支付类型">
                <Option
                  v-for="item in PayConfigTypeEnum"
                  :value="item.ID"
                  :key="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="状态:" prop="Status">
              <Select clearable v-model="params.Status" placeholder="状态">
                <Option
                  v-for="item in PayConfigStatusEnum"
                  :value="item.ID"
                  :key="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <!-- <Col span="8"
               class="mt5">
          <Button class="ml2"
                  size="small"
                  type="primary"
                  icon="ios-search"
                  @click="handleSearch">搜索</Button>
          <Button class="ml2"
                  size="small"
                  @click="handleReset">重置</Button>
          </Col> -->
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Pay_Config_Edit'])"
            @click="handleOpenAdd"
          >
            新增
          </Button>
          <Button
            type="warning"
            class="ml2"
            v-if="$canAction(['Sys_Cache_Edit'])"
            @click="$importCache('AllPayConfig', $data)"
          >
            更新缓存
            <i>{{ cacheUpdatedTime }}</i>
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
      stripe
    >
      <template slot="Action" slot-scope="{ row }">
        <Button
          class="ml2"
          size="small"
          type="primary"
          v-if="$canAction(['Pay_Config_Edit'])"
          @click="editDrawer(row)"
        >
          编辑
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <!--    支付配置信息展示抽屉-->
    <pay-config-edit ref="payConfigEdit" @on-save="onUpdated" />
  </Layout>
</template>

<script>
import bizEnum from "@/libs/bizEnum";
import payConfigEdit from "./components/pay-config-edit.vue";
import layout from "@/components/layout/index.vue";
import api from "@/api";
export default {
  name: "PayConfig",
  data() {
    return {
      RowID: 0,
      Status: 0,
      tableLoading: false,
      tableHeight: 0,
      BonusLoading: false,
      cacheUpdatedTime: "",
      params: {
        Name: "",
        Type: "",
        Status: "",
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Desc",
      },
      columns: [
        { title: "名称", key: "Name", minWidth: 100 },
        { title: "类型", key: "TypeCN", minWidth: 120 },
        { title: "状态", key: "StatusCN", minWidth: 80 },
        { title: "APPID", key: "AppID", minWidth: 135 },
        { title: "商户号", key: "MCHID", minWidth: 100 },
        { title: "开始时间", key: "DateBegin", minWidth: 100 },
        { title: "结束时间", key: "DateEnd", minWidth: 100 },
        {
          title: "操作",
          key: "action",
          slot: "Action",
          align: "center",
          fixed: "right",
          minWidth: 60,
          maxWidth: 60,
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
    };
  },
  computed: {
    PayConfigTypeEnum() {
      return bizEnum.PayConfigTypeEnum;
    },
    PayConfigStatusEnum() {
      return bizEnum.PayConfigStatusEnum;
    },
  },
  created() {
    this.loadPayConfigList();
    this.$getCacheUpdatedTime("AllPayConfig", this);
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadPayConfigList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadPayConfigList();
        this.$getCacheUpdatedTime("AllPayConfig", this);
      }
    });
  },
  methods: {
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadPayConfigList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadPayConfigList();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.DateBegin = value[0];
      this.params.DateEnd = value[1];
    },
    editDrawer(row) {
      this.$refs.payConfigEdit.obj = JSON.parse(JSON.stringify(row));
      this.$refs.payConfigEdit.usechannelArr = row.Channel.split(",");
      this.$refs.payConfigEdit.isShow = true;
      this.$refs.payConfigEdit.isEdit = true;
    },
    handleOpenAdd() {
      this.$refs.payConfigEdit.obj = {};
      this.$refs.payConfigEdit.isShow = true;
      this.$refs.payConfigEdit.isEdit = true;
      this.$refs.payConfigEdit.isAdd = true;
    },
    async loadPayConfigList() {
      this.tableLoading = true;
      try {
        const res = await api.GetPayConfigList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadPayConfigList();
    },
    handleReset() {
      this.$refs.PayConfigSearchForm.resetFields();
      this.loadPayConfigList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadPayConfigList();
    },
    onUpdated() {
      this.$delayLoad(() => {
        this.loadPayConfigList();
      });
    },
    // 修改成功，更改列表数据
    handleEditSuccess(res) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === res.ID
      );
      this.resObj.DataList.splice(index, 1, res);
    },
  },
  components: { payConfigEdit, layout },
};
</script>
